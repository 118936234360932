document.addEventListener('DOMContentLoaded', init);

function init() {
    const dropdowns = document.querySelectorAll<HTMLElement>('[data-dropdown]');
    const nav = document.querySelector<HTMLElement>('.header .nav');
    const navToggleBtn = document.querySelector<HTMLElement>('[data-toggle-nav]')
    const backdrop = document.querySelector<HTMLElement>('[data-backdrop]')
    dropdowns.forEach(dropdown => {
        dropdown.addEventListener('click', openDropdown)
    })

    navToggleBtn?.addEventListener('click', toggleNavigation)
    backdrop?.addEventListener('click', closeNavigation)
    function toggleNavigation() {
        if (nav) {
            if (nav.dataset.showNav == "1") {
                closeNavigation()
            } else {
                openNavigation()
            }
        }
    }

    function closeNavigation(){
        nav.dataset.showNav = "0"
        navToggleBtn.dataset.toggleNav = "0";
        backdrop.dataset.backdrop = "0"
    }

    function openNavigation(){
        nav.dataset.showNav = "1";
        navToggleBtn.dataset.toggleNav = "1";
        backdrop.dataset.backdrop = "1"
    }


    function openDropdown(event: Event) {
        const target = event.currentTarget as HTMLElement;

        if (target) {
            if (target.dataset.dropdown === "show") {
                closeDropDown(target)
            } else {
                target.dataset.dropdown = "show";
                document.addEventListener('click', handleClickOutside);
            }

            function handleClickOutside(event) {
                if (!target.contains(event.target)) {
                    closeDropDown(target)
                }
            }

            function closeDropDown(target) {
                target.dataset.dropdown = "";
                document.removeEventListener('click', handleClickOutside);
            }
        }
    }
}
